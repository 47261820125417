import { SliceClass } from 'interfaces';
import { OfferDocumentDataBodySquircleSlice } from 'prismic';

export class OfferStickerSlice extends SliceClass {
  text: string;
  position: string;
  color: string;

  constructor(public sliceData: OfferDocumentDataBodySquircleSlice) {
    super(sliceData);

    const primary = sliceData.primary;

    this.text = primary.text;
    this.position = primary.squircle_position;
    this.color = primary.color;
  }
}
