import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  TemplateRef,
} from '@angular/core';
import { sleep } from 'utils';
import { TooltipService } from '../tooltip.service';

@Component({
  selector: 'lib-tooltip-global',
  templateUrl: './tooltip-global.component.html',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipGlobalComponent {
  private tooltipService = inject(TooltipService);
  private cdr = inject(ChangeDetectorRef);
  template: TemplateRef<HTMLElement>;
  parentElement: ElementRef<HTMLElement>;
  position?: { left: number; top: number };
  maxWidth = 240;
  mouseOverTemplate = false;
  screens: Record<string, string>;
  currentBreakpoint = 'sm';

  @HostListener('window:scroll', ['$event']) isScrolledIntoView() {
    if (this.template) {
      this.currentBreakpoint = this.getCurrentBreakpoint();
      this.setPosition();
    }
  }

  constructor() {
    this.screens = this.getTailwindScreens();

    this.tooltipService.onUpdates.subscribe(async event => {
      if (event) {
        this.currentBreakpoint = this.getCurrentBreakpoint();
        this.template = event.template;
        this.parentElement = event.parentElement;
        this.setPosition();
      } else {
        await sleep(0);
        if (!this.mouseOverTemplate) {
          this.template = undefined;
        }
      }
      this.cdr.markForCheck();
    });
  }

  getBreakpointValue(value: string): number {
    return +this.screens[value].slice(0, this.screens[value].indexOf('px'));
  }

  getCurrentBreakpoint(): string {
    let currentBreakpoint: string;
    let biggestBreakpointValue = 0;

    for (const breakpoint of Object.keys(this.screens)) {
      const breakpointValue = this.getBreakpointValue(breakpoint);
      if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
        biggestBreakpointValue = breakpointValue;
        currentBreakpoint = breakpoint;
      }
    }
    return currentBreakpoint;
  }

  getTailwindScreens(): Record<string, string> {
    return {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    };
  }

  private setPosition() {
    let tooltipWidth = 0;

    switch (this.currentBreakpoint) {
      case 'sm':
        tooltipWidth = 320; // corresponds to class 'max-w-md'
        break;
      case 'md':
        tooltipWidth = 512; // corresponds to class 'max-w-lg'
        break;
      default:
        tooltipWidth = 576; // corresponds to class 'max-w-xl'
        break;
    }

    if (window.innerWidth < 768) {
      this.position = undefined;
    } else {
      const bounds = this.parentElement.nativeElement.getBoundingClientRect();
      this.position = { left: bounds.left, top: bounds.top + 15 };
      if (this.position.left + tooltipWidth > window.innerWidth) {
        this.position.left -= tooltipWidth - bounds.width;
      }
    }
  }

  mouseover() {
    this.mouseOverTemplate = true;
  }

  async mouseout() {
    this.mouseOverTemplate = false;
    await sleep(0);
    if (this.mouseOverTemplate) return;
    if (this.template) {
      this.template = undefined;
    }
  }

  backgroundClick(e: Event) {
    e.preventDefault();
    this.tooltipService.hide();
    this.template = undefined;
  }
}
