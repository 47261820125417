import { PrismicSlice, SliceClass } from 'interfaces';

export class OfferCountDownSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public countDown: string
  ) {
    super(sliceData);
  }
}
