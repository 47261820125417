<ng-container *ngTemplateOutlet="offerBox"></ng-container>

<ng-template #offerBox>
  <div class="relative flex flex-col h-full sales-box">
    <div *ngIf="promotionBanner">
      <lib-link
        *ngIf="offerLink; else noLinkBanner"
        [link]="offerLink"
        [queryParams]="getQueryParams()"
        (onClick)="onOfferClick()"
      >
        <lib-offer-sticker
          [slice]="promotionBanner"
          [productOfferPromotionTitle]="offer?.productListItem?.promotion?.title"
        >
        </lib-offer-sticker>
      </lib-link>
      <ng-template #noLinkBanner>
        <lib-offer-sticker
          [slice]="promotionBanner"
          [productOfferPromotionTitle]="offer?.productListItem?.promotion?.title"
        >
        </lib-offer-sticker>
      </ng-template>
    </div>

    <div
      class="relative flex flex-col sales-box bg-white h-full"
      [ngClass]="{ 'lg:flex-row': size === 'medium', 'no-top-right-radius': promotionBanner }"
    >
      <div [ngClass]="{ 'lg:flex-1': size === 'medium' }" *ngIf="offerLink; else noLink">
        <lib-link [link]="offerLink" [queryParams]="getQueryParams()" (onClick)="onOfferClick()">
          <lib-offer-box-image
            [offer]="offer"
            [size]="size"
            [boxHasBanner]="!!promotionBanner"
            [lazyLoadImage]="lazyLoadImage"
          ></lib-offer-box-image>
        </lib-link>
      </div>

      <ng-template #noLink>
        <div [ngClass]="{ 'lg:flex-1': size === 'medium' }">
          <lib-offer-box-image
            [offer]="offer"
            [size]="size"
            [boxHasBanner]="!!promotionBanner"
            [lazyLoadImage]="lazyLoadImage"
          ></lib-offer-box-image>
        </div>
      </ng-template>

      <div
        class="border-l-0 bottom-border-radius grow flex flex-col lg:justify-between px-5 pt-5 lg:px-6 lg:pt-6 text-color-product-box-description"
        [ngClass]="{ 'lg-right-border-radius lg:rounded-l-none lg:pt-10 lg:flex-1': size === 'medium' }"
      >
        <div *ngIf="showCustomContent(); else noCustomContent">
          <ngx-dynamic-hooks
            data-source="prismic"
            [content]="offer?.content"
            [options]="{ sanitize: false }"
            class="grow flex flex-col"
          >
          </ngx-dynamic-hooks>
        </div>

        <ng-template #noCustomContent>
          <div>
            <div data-source="prismic" [innerHtml]="offer.title" [class]="titleCssClass"></div>
            <div *ngIf="offer.checkMarks">
              <ng-container *ngFor="let checkMark of offer.checkMarks">
                <ngx-dynamic-hooks
                  data-source="prismic"
                  [content]="checkMark?.chek_mark_content"
                  [options]="{ sanitize: false }"
                  class="grow flex flex-col"
                ></ngx-dynamic-hooks>
              </ng-container>
            </div>
            <div class="text-center mb-6">
              <lib-offer-box-price [offer]="offer" [priceFontSize]="priceFontSize"></lib-offer-box-price>
            </div>
          </div>
        </ng-template>

        <div *ngIf="offer?.slices && offer?.slices?.length > 0">
          <ng-container *ngFor="let slice of offer.slices">
            <div *ngIf="slice.type === 'count_down'" class="pt-24">
              <lib-offer-box-count-down
                [slice]="$any(slice)"
                class="absolute w-full right-0 bottom-0"
                [ngClass]="{ 'lg:w-1/2': size === 'medium' }"
              >
              </lib-offer-box-count-down>
            </div>
            <div *ngIf="slice.type === 'offer_cta'" class="pb-5">
              <lib-offer-box-button
                class="ble"
                *ngIf="offerLink"
                (offerButtonClick)="onOfferClick()"
                [queryParams]="getQueryParams()"
                [offerLink]="offerLink"
                [slice]="$any(slice)"
                [isHeroOffer]="isHeroOffer"
              >
              </lib-offer-box-button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
