import { isPlatformBrowser, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { TranslatePipe } from 'translate';
import { sleep } from 'utils';
import { OfferCountDownSlice } from './offer-countdown.slice';

@Component({
  selector: 'lib-offer-box-count-down',
  templateUrl: './offer-box-count-down.component.html',
  styleUrls: ['./offer-box-count-down.component.scss'],
  standalone: true,
  imports: [NgClass, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferBoxCountDownComponent implements OnInit, OnDestroy {
  private platformId = inject(PLATFORM_ID);
  private ngZone = inject(NgZone);
  private cdr = inject(ChangeDetectorRef);
  @Input() slice: OfferCountDownSlice;
  @Input() isOfferBoxLight = false;
  endDate: Date;
  dateDiff: Date;
  hide = true;
  $timer: Subscription;

  async ngOnInit() {
    this.endDate = new Date(this.slice.countDown);
    this.dateDiff = new Date(this.endDate.getTime() - new Date().getTime());

    if (isPlatformBrowser(this.platformId)) {
      // Cannot let this run on the server as it will then run indefinitely (and never return to the client)
      this.ngZone.runOutsideAngular(() => {
        this.$timer = timer(0, 1000).subscribe(() => {
          this.dateDiff = new Date(this.endDate.getTime() - new Date().getTime());

          this.ngZone.run(() => this.cdr.markForCheck());
        });
      });

      await sleep(0);
      this.hide = false;
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.$timer?.unsubscribe();
  }

  get days(): number {
    return this.dateDiff.getUTCDate() - 1;
  }

  get hours(): number {
    return this.dateDiff.getUTCHours();
  }

  get minutes(): number {
    return this.dateDiff.getUTCMinutes();
  }

  get seconds(): number {
    return this.dateDiff.getUTCSeconds();
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
