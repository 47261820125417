<div
  class="flex pb-6 pt-2 transition-all"
  [class.opacity-0]="hide"
  [class.-translate-y-2]="hide"
  [ngClass]="{ 'countdown-for-offer-light': isOfferBoxLight, 'justify-center': !isOfferBoxLight }"
>
  <div [ngClass]="{ 'mx-1': !isOfferBoxLight, 'mr-1': isOfferBoxLight }">
    <h3 class="count-down__title">{{ days }}</h3>
    <p class="count-down__text">{{ ['days'] | translate }}</p>
  </div>
  <div class="mx-1">
    <h3 class="count-down__title">:</h3>
  </div>
  <div class="mx-1">
    <h3 class="count-down__title">{{ hours }}</h3>
    <p class="count-down__text">{{ ['hours'] | translate }}</p>
  </div>
  <div class="mx-1">
    <h3 class="count-down__title">:</h3>
  </div>
  <div class="mx-1">
    <h3 class="count-down__title">{{ minutes }}</h3>
    <p class="count-down__text">{{ ['minutes'] | translate }}</p>
  </div>
  <div class="mx-1">
    <h3 class="count-down__title">:</h3>
  </div>
  <div class="mx-1">
    <h3 class="count-down__title">{{ seconds }}</h3>
    <p class="count-down__text">{{ ['seconds'] | translate }}</p>
  </div>
</div>
