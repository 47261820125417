<div *ngIf="offer?.productListItem?.product">
  <div data-source="prismic" [innerHtml]="offer?.textAfterCheckMarks" class="my-2"></div>
  <div data-source="prismic">
    <span [class]="priceFontSize" *ngIf="offer?.pricePrefix">{{ offer?.pricePrefix }}</span>
    <span [class]="priceFontSize" class="text-red"> CHF</span>

    <span [class]="priceFontSize">
      <span class="text-red">
        {{
          offer?.productListItem.product.discountedPrice(this.offer?.productListItem?.promotion?.discountAmount)
            | currencyFormatter
        }}</span
      >
      <small>
        <span class="crossed-out"> {{ offer?.productListItem?.product?.monthly_cost | currencyFormatter }}</span></small
      >
    </span>
  </div>
  <div data-source="prismic" [innerHtml]="offer?.textAfterPrice" class="my-2"></div>
</div>
