import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SvgComponent } from 'icon';
import { randomString } from 'utils';
import { TooltipService } from '../tooltip.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit {
  private tooltipService = inject(TooltipService);
  private el = inject(ElementRef);
  @ViewChild('tooltipContent') tooltipContent: TemplateRef<HTMLElement>;
  @Input() icon = 'tooltip';
  @Input() iconClass = 'md:mr-1 normal';
  iconClasses: string;
  id = randomString(5);

  ngOnInit() {
    this.iconClasses = this.iconClass;
    this.tooltipService.onUpdates.subscribe(event => {
      if (event && event.id === this.id) {
        this.iconClasses = this.iconClass + ' active';
      } else {
        this.iconClasses = this.iconClass;
      }
    });
  }

  mouseover() {
    this.show(this.id);
  }

  mouseout() {
    this.hide();
  }

  touchstart(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.show(this.id);
  }

  private show(id: string) {
    this.tooltipService.show(this.tooltipContent, this.el, id);
  }

  private hide() {
    this.tooltipService.hide();
  }
}
