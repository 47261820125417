import { LinkField } from '@prismicio/client';
import { PrismicSlice, SliceClass } from 'interfaces';

export class OfferCtaSlice extends SliceClass {
  constructor(
    public sliceData: PrismicSlice,
    public buttonText: string,
    public buttonLink: LinkField,
    public buttonColor: string
  ) {
    super(sliceData);
  }
}
